import React, { Component } from "react";
import Spinner from 'react-spinkit';
import NotFound from "./NotFound";
import axios from 'axios';

import "../css/Home.css";

export default class Download extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tinyUrlCode: null,
      s3Url: null,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.setState({
      tinyUrlCode: this.props.match.params.code,
    }, () => {
      let config = {
        params: {
          tinyurlCode: this.state.tinyUrlCode,
        },
      };
      axios.get(this.props.endpoints.itarXferLookupTinyUrlCode, config)
      .then(response => {
        this.setState({
          s3Url: response.data.s3_url,
          loading: false,
        });
      }).catch(error => {
        this.setState({
          loading: false,
          error: true,
        });
      });
    })
  }

  renderLoading() {
    return (
      <div className="mx-auto row mt-5" style={{ width: 75 }}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8" />
      </div>
    );
  }

  renderRedirect() {
    window.location.replace(this.state.s3Url);
    return null;
  }

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>ITAR File Download</h1>
          {!this.state.loading && this.state.s3Url && <h3>File downloading ... please check your downloads folder.</h3>}
          {this.state.loading && this.renderLoading()}
          {!this.state.loading && this.state.error && <NotFound />}
          {!this.state.loading && this.state.s3Url && this.renderRedirect()}
        </div>
      </div>
    );
  }
}
