import React, { Component } from "react";
import { Form, Button, Row, Col, ProgressBar } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Mutex } from 'async-mutex';
import Spinner from 'react-spinkit';
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';
import "../css/Home.css";

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.authz = this.authz.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.uploadFile = this.uploadFile.bind(this);

    this.state = {
      authz: false,
      authErr: false,
      loading: false,
      uploading: false,
      submit: false,
      secret: null,
      file: null,
      uploadPct: 0,
      progressMap: {},
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleFileUpload(event) {
    this.setState({ file: event.target.files[0] })
  }

  authz() {
    let data = {
      'secret': this.state.secret
    };
    this.setState({
      loading: true,
      authErr: false,
    }, () => {
      axios.post(this.props.endpoints.itarXferAuthzSecret, data)
        .then(response => {
          this.setState({
            authz: true,
            loading: false,
            authErr: false,
          });
        }).catch(error => {
          this.setState({
            authz: false,
            authErr: true,
            loading: false,
          });
        });
    });
  }

  async uploadFile() {
    // get file size -- use single part upload if under 10MB
    if (this.state.file.size < 10000000) {
      // do single-part upload
      let data = {
        secret: this.state.secret,
        filename: this.state.file.name,
        filetype: this.state.file.type
      };
      this.setState({
        uploading: true,
      }, async () => {
        axios.post(this.props.endpoints.itarXferSinglePartUpload, data)
          .then(response => {
            let options = {
              headers: {
                'Content-Type': this.state.file.type,
                'x-amz-tagging': "Description=" + response.data.descr
              },
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                  this.setState({ uploadPct: percent });
                }
              },
            };
            axios.put(response.data.signed_url, this.state.file, options)
              .then(result => {
                // console.log("*** S3 PutObject result: " + result);
                this.setState({
                  uploading: false,
                  uploadPct: 0,
                  progressMap: {},
                  file: null,
                }, () => {
                  document.getElementById('filepicker').value = null;
                  toast.success('File Upload Succeeded!');
                });
              })
              .catch(err => {
                this.setState({
                  authz: false,
                  authErr: true,
                  loading: false,
                  uploading: false,
                  submit: false,
                  secret: null,
                  file: null,
                  uploadPct: 0,
                  progressMap: {},
                }, () => {
                  document.getElementById('filepicker').value = null;
                  toast.error('File Upload Failed!');
                });
                console.log(err);
              });
          })
          .catch(function (err) {
            console.log("*** error calling " + this.props.endpoints.itarXferSinglePartUpload + ": " + err);
          });
      });
    } else {
      this.setState({
        uploading: true,
      }, async () => {
        // multipart upload
        let data = {
          secret: this.state.secret,
          filename: this.state.file.name,
          filetype: this.state.file.type,
          filesize: this.state.file.size
        };
        // mutex for exclusive access to file progress bar
        const mutex = new Mutex();
        axios.post(this.props.endpoints.itarXferStartMultipartUpload, data)
          .then(async (result) => {
            // function to generate async XHR requests -- needed because axios has bug with PUT blob data
            var makeXHRRequest = function (url, data, mutex, thisRef) {
              // Create the XHR request
              var request = new XMLHttpRequest();
              // Return it as a Promise
              return new Promise(function (resolve, reject) {
                // Setup our listener to process completed requests
                request.onreadystatechange = () => {
                  // Only run if the request is complete
                  if (request.readyState !== 4) return;
                  // Process the response
                  if (request.status >= 200 && request.status < 300) {
                    // If successful
                    resolve(request);
                  } else {
                    // If failed
                    reject({
                      status: request.status,
                      statusText: request.statusText
                    });
                  }
                };
                // onprogress event handler, for updating progress bar
                request.upload.onprogress = async (progressEvent) => {
                  const release = await mutex.acquire();
                  try {
                    let _progressMap = { ...thisRef.state.progressMap };
                    _progressMap[url] = progressEvent.loaded;

                    let totalUploaded = 0;
                    Object.keys(_progressMap).forEach(item => {
                        totalUploaded += _progressMap[item];
                    });
                    let percentCompleted = Math.round((totalUploaded * 100) / thisRef.state.file.size);;
                    if (percentCompleted <= 100) {
                      thisRef.setState({
                        uploadPct: percentCompleted,
                        progressMap: _progressMap
                      })
                    }
                  } finally {
                    release();
                  }
                };
                // Setup our HTTP request
                request.open('PUT', url, true);
                // Send the request
                request.send(data);
              });
            };

            const {
              upload_id,
              descr,
              chunks,
              chunk_size,
            } = result.data;
            let start, end, blob;
            let promisesArray = [];
            for (let index = 1; index < chunks + 1; index++) {
              start = (index - 1) * chunk_size;
              end = (index) * chunk_size;
              // get slice of data for this part
              blob = (index < chunks) ? this.state.file.slice(start, end) : this.state.file.slice(start);

              /*
              *  (1) Generate presigned URL for this part
              */
              let data = {
                secret: this.state.secret,
                filename: this.state.file.name,
                part_num: index,
                upload_id: upload_id
              };
              let getUploadUrlResp = await axios.post(
                this.props.endpoints.itarXferGetUploadPartUrl,
                data
              );

              /*
              * (2) PUT part into S3
              */
              var xhr = makeXHRRequest(
                getUploadUrlResp.data.signed_url,
                blob,
                mutex,
                this
              );
              promisesArray.push(xhr);
            }

            // wait for all part uploads to complete
            let resolvedArray = await Promise.all(promisesArray);
            let uploadPartsArray = [];
            resolvedArray.forEach((xhr, index) => {
                if (xhr.getAllResponseHeaders().indexOf('etag') >= 0) {
                  uploadPartsArray.push({
                      ETag: xhr.getResponseHeader('etag'),
                      PartNumber: index + 1
                  });
                }
            });

            /*
            *  (3) Call the CompleteMultipartUpload endpoint on backend
            */
            let data = {
              secret: this.state.secret,
              filename: this.state.file.name,
              parts: uploadPartsArray,
              upload_id: upload_id
            };
            let completeUploadResp = await axios.post(
              this.props.endpoints.itarXferCompleteMultipartUpload,
              data
            );
          })
          .then(() => {
            this.setState({
              uploading: false,
              uploadPct: 0,
              progressMap: {},
              file: null,
            }, () => {
              document.getElementById('filepicker').value = null;
              toast.success('File Upload Succeeded!');
            });
          })
          .catch(err => {
            this.setState({
              authz: false,
              authErr: true,
              loading: false,
              uploading: false,
              submit: false,
              secret: null,
              file: null,
              uploadPct: 0,
              progressMap: {},
            }, () => {
              if (document.getElementById('filepicker') !== null) {
                document.getElementById('filepicker').value = null;
              };
              toast.error('File Upload Failed!');
            });
            console.log(err);
          });
      });
    }
  }

  renderAuthz() {
    return (
      <>
        <p>Login with your secret key to begin</p>
        <Col md={4} className="mx-auto text-center">
          {
            this.state.authErr &&
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">Unauthorized</h4>
              <p>You are not authorized to access this application.</p>
            </div>
          }
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder="Enter secret key"
                id="secret"
                name="secret"
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
          <Button variant="primary" type="submit" size="lg" disabled={this.state.secret === null} onClick={this.authz}>
            Login
          </Button>
        </Col>
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row mt-5" style={{ width: 75 }}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8" />
      </div>
    );
  }

  renderUploadForm() {
    return (
      <>
        <div className="form-group text-center mx-auto col-md-4 my-3">
          <h3>
            Select File for Upload
          </h3>
          <br />
          <input type="file"
            id="filepicker"
            name="filepicker"
            className="mt-2"
            onChange={this.handleFileUpload}
          >
          </input>
        </div>
        <Button variant="primary" type="submit" size="lg" disabled={this.state.file === null || this.state.uploading} onClick={this.uploadFile}>
          Upload
        </Button>
        {
          this.state.uploading &&
          <div className="text-center mx-auto col-md-4 my-2">
            <ProgressBar animated now={this.state.uploadPct} label={`${this.state.uploadPct}%`} />
          </div>
        }
      </>
    )
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="lander">
          <h1>ITAR File Upload</h1>
          {!this.state.authz && !this.state.loading && this.renderAuthz()}
          {this.state.loading && this.renderLoading()}
          {this.state.authz && !this.state.loading && this.renderUploadForm()}
        </div>
      </div>
    );
  }
}
