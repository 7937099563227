import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "./containers/Home";
import Admin from "./containers/Admin";
import Download from "./containers/Download";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) => {
    return (
        <BrowserRouter>
            <Switch>
                <AppliedRoute path="/" exact component={Home} props={childProps} />
                <AppliedRoute path="/admin" exact component={Admin} props={childProps} />
                <AppliedRoute path="/download/:code" exact component={Download} props={childProps} />
                <Route component={NotFound} />
                {/* <AppliedRoute path="/login" exact component={Login} props={childProps} /> */}
            </Switch>
        </BrowserRouter>
    )
}
 