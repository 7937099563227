import React, { Component } from "react";
import { Container, Navbar, Col } from 'react-bootstrap';
import Routes from "./Routes";
import moment from "moment";
import { endpoints, downloadBaseUrl } from "./config";
import logo from './images/ant-logo.png';
import minilogo from './images/logo-A-orbits-vector.png';

import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar bg="dark" fixed="top" className="px-0">
          <Container>
            <Navbar.Brand href="/" className="w-100 mx-auto text-center">
              <img
                src={logo}
                width="357"
                height="100"
                alt="Ascending Node Technologies"
              />
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Routes childProps={{ endpoints: endpoints, downloadBaseUrl: downloadBaseUrl }} />
        <footer className="footer bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-sm-6 my-auto">
                <img alt="Logo-Orbital-A" className="img-responsive a-atom-logo" src={minilogo}/>
              </div>
              <div className="col-lg-6 col-sm-6 h-100 text-center text-lg-left my-auto">
                <p className="text-muted small mb-4 mb-lg-0">&copy; Ascending Node Technologies, {moment().format('YYYY')}. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
