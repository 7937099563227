import React, { Component } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-spinkit';
import DateTimePicker from 'react-datetime-picker';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cookies from 'js-cookie'
import axios from 'axios';
import moment from "moment";
import queryString from 'query-string'


import 'react-toastify/dist/ReactToastify.css';
import "../css/Home.css";

export default class Admin extends Component {

  constructor(props) {
    super(props);

    this.authn = this.authn.bind(this);
    this.createUploadToken = this.createUploadToken.bind(this);
    this.createDownloadUrl = this.createDownloadUrl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTokenExpChange = this.handleTokenExpChange.bind(this);
    this.handleDownloadUrlExpChange = this.handleDownloadUrlExpChange.bind(this);
    this.resetState = this.resetState.bind(this);

    this.state = {
      authn: false,
      adminToken: null,
      uploadToken: null,
      authErr: false,
      loading: false,
      submit: false,
      username: null,
      password: null,
      secret_descr: null,
      showCreateToken: false,
      s3Filename: '',
      showCreateDownloadUrl: false,
      downloadUrl: null,
      downloadUrlExp: moment().add(24, 'hours').toDate(),
      tokenExp: moment().add(24, 'hours').toDate(),
      copied: false,
    };
  }

  resetState() {
    this.setState({
      authn: false,
      adminToken: null,
      uploadToken: null,
      authErr: false,
      loading: false,
      submit: false,
      username: null,
      password: null,
      secret_descr: null,
      showCreateToken: false,
      s3Filename: '',
      showCreateDownloadUrl: false,
      downloadUrl: null,
      downloadUrlExp: moment().add(24, 'hours').toDate(),
      tokenExp: moment().add(24, 'hours').toDate(),
      copied: false,
    });
  }

  componentDidMount() {
    let values = queryString.parse(window.location.search);
    if (Object.keys(values).includes('s3ObjectKey')) {
      this.setState({
        s3Filename: values.s3ObjectKey
      }, () => {
        console.log(JSON.stringify(this.state));
      });
    }

    let authz_token = Cookies.get('admin_authz_token');
    if (authz_token) {
      let data = {
        token: authz_token,
      };
      this.setState({
        loading: true,
        authErr: false,
      }, () => {
        axios.post(this.props.endpoints.itarXferAdminAuthz, data)
          .then(response => {
            this.setState({
              authn: true,
              adminToken: authz_token,
              loading: false,
            });
          }).catch(error => {
            this.resetState();
          });
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleTokenExpChange(expTime) {
    this.setState({ tokenExp: expTime });
  }

  handleDownloadUrlExpChange(expTime) {
    this.setState({ downloadUrlExp: expTime });
  }

  authn() {
    let data = {
      username: this.state.username,
      password: this.state.password,
    };
    this.setState({
      loading: true,
      authErr: false,
    }, () => {
      axios.post(this.props.endpoints.itarXferAdminAuthn, data)
        .then(response => {
          this.setState({
            authn: true,
            adminToken: response.data.token,
            loading: false,
            authErr: false,
          }, () => {
            Cookies.set('admin_authz_token', this.state.adminToken)
          });
        }).catch(error => {
          this.setState({authErr: true, loading: false});
        });
    });
  }

  createUploadToken() {
    let data = {
      description: this.state.secret_descr,
      expiration: moment(this.state.tokenExp).unix(),
      token: this.state.adminToken
    };
    this.setState({
      loading: true,
      authErr: false,
    }, () => {
      axios.post(this.props.endpoints.itarXferCreateSecret, data)
        .then(response => {
          this.setState({
            uploadToken: response.data.secret,
            showCreateToken: false,
            loading: false,
          });
        }).catch(error => {
          this.resetState();
        });
    });
  }

  createDownloadUrl() {
    let data = {
      filename: this.state.s3Filename,
      expire_timestamp: moment(this.state.downloadUrlExp).unix(),
      token: this.state.adminToken
    };
    this.setState({
      loading: true,
      authErr: false,
    }, () => {
      axios.post(this.props.endpoints.itarXferCreateTinyUrlCode, data)
        .then(response => {
          this.setState({
            downloadUrl: this.props.downloadBaseUrl + '/' + response.data.tinyurl_code,
            showCreateDownloadUrl: false,
            showDownloadUrl: true,
            loading: false,
          });
        }).catch(error => {
          this.resetState();
        });
    });
  }

  renderOptions() {
    return (
      <>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <Button variant="success" type="button" size="lg" onClick={() => { this.setState({ showCreateToken: true }) }} className="mt-3">
              Create Upload Token
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <Button variant="warning" type="button" size="lg" onClick={() => { this.setState({ showCreateDownloadUrl: true }) }} className="mt-3">
              Create Download URL
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  renderUploadToken() {
    return (
      <>
        <h3 className="mb-4">Upload Token Result</h3>
        <p>Your upload token for <strong>{this.state.secret_descr}</strong> is below. This token will expire at <strong>{moment(this.state.tokenExp).format('llll')}</strong>.</p>
        <p>Make sure to record this upload token somewhere safe, as this is the only time it will be displayed.</p>
        <code style={{ fontSize: '1.25em' }}>{this.state.uploadToken}</code>
        <div className="mt-3">
          <CopyToClipboard text={`Upload URL: https://itar.ascendingnode.tech/\nUpload Token: ${this.state.uploadToken}\nUpload Token Expiration: ${moment(this.state.tokenExp).format('llll')}`}
            onCopy={() => this.setState({ copied: true })}>
            <Button variant="info" type="button" size="sm">Copy 📋</Button>
          </CopyToClipboard>
          {this.state.copied ? <p style={{ color: 'red' }}>Copied</p> : null}
        </div>
        <div className="mt-3">
          <Button variant="secondary" type="button" size="lg" onClick={() => {window.location.href="/admin"}}>Close</Button>
        </div>
      </>
    )
  }

  renderCreateUploadToken() {
    const now = new Date();
    return (
      <>
        <h3>Create Upload Token</h3>
        <p>Enter Description and Expiration Time</p>
        <Col md={{ span: 4, offset: 4 }} style={{ textAlign: "left" }}>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                id="secret_descr"
                name="secret_descr"
                onChange={this.handleChange}
              />
              {
                this.state.secret_descr && !this.state.secret_descr.match(/^[a-zA-Z0-9\-._:/@ ]*$/) &&
                <Form.Label style={{ color: 'red' }}><em>Valid characters are letters, numbers, spaces and&nbsp;</em><pre style={{display: "inline", color: 'red'}}>-._:/@</pre></Form.Label>
              }
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expiration</Form.Label>
              <div>
                <DateTimePicker
                  disableClock={true}
                  maxDate={moment().add(7, 'days').toDate()}
                  minDate={moment().add(10, 'minutes').toDate()}
                  value={this.state.tokenExp}
                  onChange={this.handleTokenExpChange}
                />
              </div>
            </Form.Group>
          </Form>
          <Button variant="primary" type="button" size="lg" disabled={!this.state.secret_descr || this.state.tokenExp <= now || !this.state.secret_descr.match(/^[a-zA-Z0-9\-._:/@ ]*$/)} onClick={this.createUploadToken}>
            Submit
          </Button>
        </Col>
      </>
    );
  }

  renderDownloadUrl() {
    return (
      <>
        <h3 className="mb-4">Download URL Result</h3>
        <p>Your download URL for <strong>{this.state.s3Filename}</strong> is below. This URL will expire at <strong>{moment(this.state.downloadUrlExp).format('llll')}</strong>.</p>
        <p>Make sure to record this download URL somewhere safe, as this is the only time it will be displayed.</p>
        <Col md={{ span: 8, offset: 2 }} style={{ textAlign: "center" }}>
          <code style={{ fontSize: '1.25em' }}>{this.state.downloadUrl}</code>
          <div className="mt-3">
            <CopyToClipboard text={this.state.downloadUrl}
              onCopy={() => this.setState({ copied: true })}>
              <Button variant="info" type="button" size="sm">Copy 📋</Button>
            </CopyToClipboard>
            {this.state.copied ? <p style={{ color: 'red' }}>Copied</p> : null}
          </div>
        </Col>
        <div className="mt-3">
          <Button variant="secondary" type="button" size="lg" onClick={() => {window.location.href="/admin"}}>Close</Button>
        </div>
      </>
    )
  }

  renderCreateDownloadUrl() {
    const now = new Date();
    return (
      <>
        <h3>Create Download URL</h3>
        <p>Enter S3 filename and time URL should expire (max 7 days)</p>
        <Col md={{ span: 4, offset: 4 }} style={{ textAlign: "left" }}>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>S3 Filename</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter filename"
                id="s3Filename"
                name="s3Filename"
                value={this.state.s3Filename}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expiration</Form.Label>
              <div>
                <DateTimePicker
                  disableClock={true}
                  minDate={moment().toDate()}
                  maxDate={moment().add(7, 'days').toDate()}
                  value={this.state.downloadUrlExp}
                  onChange={this.handleDownloadUrlExpChange}
                />
              </div>
            </Form.Group>
          </Form>
          <Button variant="primary" type="button" size="lg" disabled={this.state.s3Filename.length === 0 || this.state.downloadUrlExp <= now} onClick={this.createDownloadUrl}>
            Submit
          </Button>
        </Col>
      </>
    );
  }

  renderLogin() {
    return (
      <>
        <p>Login with your username and password</p>
        <Col md={{ span: 4, offset: 4 }} style={{ textAlign: "left" }}>
          {
            this.state.authErr &&
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">Unauthorized</h4>
              <p>You are not authorized to access this application.</p>
            </div>
          }
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                id="username"
                name="username"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                id="password"
                name="password"
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
          <Button variant="primary" type="submit" size="lg" disabled={this.state.username === null || this.state.password === null} onClick={this.authn}>
            Login
          </Button>
        </Col>
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row mt-5" style={{ width: 75 }}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8" />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="lander">
          <h1>ITAR File Upload Admin Page</h1>
          {!this.state.authn && !this.state.loading && this.renderLogin()}
          {this.state.loading && this.renderLoading()}
          {this.state.authn && !this.state.loading && this.state.showCreateToken && this.renderCreateUploadToken()}
          {this.state.authn && !this.state.loading && this.state.uploadToken && this.renderUploadToken()}
          {this.state.authn && !this.state.loading && this.state.showCreateDownloadUrl && this.renderCreateDownloadUrl()}
          {this.state.authn && !this.state.loading && this.state.downloadUrl && this.renderDownloadUrl()}
          {this.state.authn && !this.state.loading && !this.state.showCreateToken && !this.state.uploadToken && !this.state.showCreateDownloadUrl && !this.state.downloadUrl && this.renderOptions()}
        </div>
      </div>
    );
  }
}
